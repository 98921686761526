import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
var isTokenValid = function (token) {
    if (!token)
        return false;
    try {
        var decoded = jwtDecode(token);
        var currentTime = Math.floor(Date.now() / 1000);
        return decoded.exp > currentTime;
    }
    catch (_a) {
        return false;
    }
};
export var ProtectedRoute = function (_a) {
    var children = _a.children;
    var token = localStorage.getItem("accessToken");
    if (!isTokenValid(token)) {
        return _jsx(Navigate, { to: '/auth' });
    }
    return children;
};
