import { useCallback } from 'react';
export var useOpacity = function () {
    return useCallback(function (scroll, max) {
        return 1 - (Math.pow((scroll - max), 2) * 0.000025);
    }, []);
};
export var usePlanetOpacity = function () {
    return useCallback(function (scroll) {
        if (scroll < 801) {
            return 100;
        }
        if (scroll >= 801 && scroll < 1001) {
            return -0.5 * scroll + 500;
        }
        else {
            return 0;
        }
    }, []);
};
export var useStaticPlanetOpacity = function () {
    return useCallback(function (scroll) {
        if (scroll < 1001) {
            return 1;
        }
        if (scroll >= 1001 && scroll < 1201) {
            var k = (100 - 1) / (1200 - 1000);
            var b = 1 - k * 1000;
            return k * scroll + b;
        }
        else {
            return 100;
        }
    }, []);
};
export var usePlanetBlockStageY = function () {
    return useCallback(function (basePos, scroll) {
        return basePos - (scroll / 2);
    }, []);
};
export var usePlanetBlockStageX = function () {
    return useCallback(function (basePos, baseScroll, scroll) {
        return basePos - ((baseScroll - scroll) / 100);
    }, []);
};
export var useLineaEquation = function (_a) {
    var yPoints = _a.yPoints, xPoints = _a.xPoints;
    return useCallback(function (scroll) {
        if (scroll >= xPoints[xPoints.length - 1]) {
            return yPoints[yPoints.length - 1];
        }
        for (var i = 0; i < yPoints.length - 1; i++) {
            if (scroll >= xPoints[i] && scroll <= xPoints[i + 1]) {
                var k = (yPoints[i + 1] - yPoints[i]) / (xPoints[i + 1] - xPoints[i]);
                var b = yPoints[i] - k * xPoints[i];
                return k * scroll + b;
            }
        }
        return 0;
    }, [yPoints, xPoints]);
};
