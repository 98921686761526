var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var HeaderNavigationContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    gap: 48px;\n    // color: red;\n    // padding-left: 15px;\n\n    @media(max-width: 950px){\n        display: flex;\n        flex-direction: column;\n        gap: 28px;\n    }\n"], ["\n    display: flex;\n    gap: 48px;\n    // color: red;\n    // padding-left: 15px;\n\n    @media(max-width: 950px){\n        display: flex;\n        flex-direction: column;\n        gap: 28px;\n    }\n"])));
export var DropDownButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    position: relative;\n    gap: 10px;\n    align-items: center;\n    cursor: pointer;\n    \n    &:hover .dropDownButtonText{\n        color: #FFFFFF;\n    }\n    \n    &:hover .dropDownButtonIcon{\n        filter: brightness(100%);\n    }\n\n    @media(max-width: 950px){\n        flex-direction: column;\n        align-items: start;\n    }\n\n"], ["\n    display: flex;\n    position: relative;\n    gap: 10px;\n    align-items: center;\n    cursor: pointer;\n    \n    &:hover .dropDownButtonText{\n        color: #FFFFFF;\n    }\n    \n    &:hover .dropDownButtonIcon{\n        filter: brightness(100%);\n    }\n\n    @media(max-width: 950px){\n        flex-direction: column;\n        align-items: start;\n    }\n\n"])));
export var DropDownButtonText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-weight: 500;\n    display: flex;\n    gap: 10px;\n    color: #D9D9E3;\n    transition: all 0.1s linear 0s;\n    font-size: 12px;\n\n    @media(max-width: 900px){\n        font-size: 16px;\n    }\n"], ["\n    font-weight: 500;\n    display: flex;\n    gap: 10px;\n    color: #D9D9E3;\n    transition: all 0.1s linear 0s;\n    font-size: 12px;\n\n    @media(max-width: 900px){\n        font-size: 16px;\n    }\n"])));
export var DropDownButtonIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 14px;\n    height: 8px;\n    filter: brightness(50%);\n    transition: all 0.1s linear 0s;\n    background-image: url(\"", "/images/header-navigation-icon.webp\");\n    background-size: cover;\n    background-position: center;\n"], ["\n    width: 14px;\n    height: 8px;\n    filter: brightness(50%);\n    transition: all 0.1s linear 0s;\n    background-image: url(\"", "/images/header-navigation-icon.webp\");\n    background-size: cover;\n    background-position: center;\n"])), process.env.REACT_APP_API_URL);
export var ArrowVisibility = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    @media(max-width: 950px){\n        display: none;\n    }\n\n"], ["\n    @media(max-width: 950px){\n        display: none;\n    }\n\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
