import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { FormContainer, FormFlagPicker, LogInTitle, VerifyText, } from "../../../assets/styles/logIn.style";
import { PositionLayout, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import { LanguagePicker } from "../../../components/authComponents/FlagPicker";
import { useNavigate } from "react-router-dom";
import { useDataStore } from "../../../store/user/setUserData";
import { NavigateBtn } from '../../../components/authComponents/NextBtn';
import { FormattedMessage } from "react-intl";
export var UserResidensy = function () {
    var navigate = useNavigate();
    var _a = useState(false), userCountry = _a[0], setUserCountry = _a[1];
    var _b = useDataStore(), userDataObj = _b.userDataObj, addData = _b.addData;
    var handleCountrySelect = function (country) {
        setUserCountry(true);
        addData("residency", country);
    };
    var handleNext = function () {
        if (userCountry) {
            navigate("/registration/personal-information");
        }
    };
    return (_jsx(StyledRow, { "$justifyContent": "center", style: { height: "100%" }, children: _jsxs(StyledCol, { "$lg": 7, "$md": 12, children: [_jsx(StyledCol, { "$justifyContent": "center", "$xl": 6, "$lg": 8, "$md": 7, "$sm": 10, "$xs": 12, children: _jsxs(FormContainer, { children: [_jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "residensy_title" }) }), _jsx(VerifyText, { children: _jsx(FormattedMessage, { id: "residensy_title_description" }) }), _jsxs(FormFlagPicker, { children: [_jsx(LanguagePicker, { textOptionId: "residency_option", onCountrySelect: handleCountrySelect }), _jsx(PositionLayout, { margin: "0px 0px 25px 0px", children: _jsx(VerifyText, { children: _jsx(FormattedMessage, { id: "residency_text" }) }) }), _jsx(NavigateBtn, { link: "/registration/personal-information", onClick: handleNext, validate: userCountry })] })] }) }), _jsx(StyledCol, { "$justifyContent": "center", "$xl": 6, "$lg": 4, "$md": 5, "$sm": 2, "$xs": 0, style: { padding: 0 } })] }) }));
};
