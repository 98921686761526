export var kycInfo = [
    {
        messageId: 'reqiurements_kyc1',
        defaultMessage: 'Document has not expired',
    },
    {
        messageId: 'reqiurements_kyc2',
        defaultMessage: 'This is an original document, not a scan or copy',
    },
    {
        messageId: 'reqiurements_kyc3',
        defaultMessage: 'Remove any card holders or covers to avoid reflections or blur',
    },
    {
        messageId: 'reqiurements_kyc4',
        defaultMessage: 'Place documents against a solid-colored background',
    },
    {
        messageId: 'reqiurements_kyc5',
        defaultMessage: 'The document should be held straight, not tilted or rotated.',
    },
];
export var personInfo = [
    {
        messageId: 'person_requirements1',
        defaultMessage: 'The image must be sharp, without any blur or pixelation.',
    },
    {
        messageId: 'person_requirements2',
        defaultMessage: "Both the user's face and the document must be fully visible and not cropped.",
    },
    {
        messageId: 'person_requirements3',
        defaultMessage: 'The information on the document should be clearly visible and not covered by fingers.',
    },
    {
        messageId: 'person_requirements4',
        defaultMessage: 'The document should be held straight, not tilted or rotated.',
    }
];
