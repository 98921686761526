var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Link } from "react-router-dom";
export var LogoContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var LogoLink = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\n    display: flex;\n    color: white;\n    gap: 10px;\n    text-align: start;\n    font-family: \"Kodchasan\", sans-serif;\n    text-decoration: none;\n\n"], ["\n\n    display: flex;\n    color: white;\n    gap: 10px;\n    text-align: start;\n    font-family: \"Kodchasan\", sans-serif;\n    text-decoration: none;\n\n"])));
export var LogoTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    text-transform: uppercase;\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 26px;\n"], ["\n    text-transform: uppercase;\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 26px;\n"])));
export var LogoSubTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 10px;\n    font-weight: 300;\n    line-height: 12px;\n"], ["\n    font-size: 10px;\n    font-weight: 300;\n    line-height: 12px;\n"])));
export var LogoImg = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 39px;\n    height: 39px;\n    background-size: cover;\n    background-position: center;\n"], ["\n    width: 39px;\n    height: 39px;\n    background-size: cover;\n    background-position: center;\n"])));
export var LogoText = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
