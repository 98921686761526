var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
export var useUserStore = create(function (set) { return ({
    userObj: {},
    addProp: function (prop, value) {
        return set(function (state) {
            var _a;
            return ({
                userObj: __assign(__assign({}, state.userObj), (_a = {}, _a[prop] = value, _a)),
            });
        });
    },
    resetUserObj: function () { return set({ userObj: {} }); },
}); });
export var useUserLoginStore = create(function (set) { return ({
    userObj: {},
    addProp: function (prop, value) {
        return set(function (state) {
            var _a;
            return ({
                userObj: __assign(__assign({}, state.userObj), (_a = {}, _a[prop] = value, _a)),
            });
        });
    },
    resetUserObj: function () { return set({ userObj: {} }); },
}); });
