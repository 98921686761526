import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import { StyledMoreBtn } from "../../assets/styles/profile.style";
export var ContextAwareToggle = function (_a) {
    var children = _a.children, eventKey = _a.eventKey, callback = _a.callback, isBottom = _a.isBottom;
    var activeEventKey = useContext(AccordionContext).activeEventKey;
    var decoratedOnClick = useAccordionButton(eventKey, function () { return callback && callback(eventKey); });
    var isCurrentEventKey = activeEventKey === eventKey;
    if (!isBottom && isCurrentEventKey) {
        return null;
    }
    return (_jsx(StyledMoreBtn, { type: "button", onClick: decoratedOnClick, children: isCurrentEventKey ? "Collapse" : "More..." }));
};
