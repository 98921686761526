import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { BlinkingDot, FilterBtn, FilterDiv, FilterIcon, FilterSwitch, PrifileFilterContainer, ResponsiveContainer, StyledBlikingText, StyledDropdown, StyledInputGroup, } from "../../assets/styles/profile.style";
import Search from "../../assets/img/search 01.svg";
import Filter from "../../assets/img/filter.svg";
import GridIcon from "../../assets/img/grid 02.svg";
import ListIcon from "../../assets/img/list view-rectangle.svg";
import { DropdownList } from "../../const/profile/profileList";
import { Col, Dropdown, Form, InputGroup, Row, Stack } from "react-bootstrap";
export var ProfileFilters = function () {
    var _a = useState(true), isGridView = _a[0], setIsGridView = _a[1];
    var _b = useState(DropdownList[0].option), selectedOption = _b[0], setSelectedOption = _b[1];
    var toggleGridView = function () {
        if (!isGridView)
            setIsGridView(true);
    };
    var toggleListView = function () {
        if (isGridView)
            setIsGridView(false);
    };
    var handleSelect = function (option) {
        setSelectedOption(option);
    };
    return (_jsxs(Row, { children: [_jsx(Col, { lg: 4, md: 5, xs: 10, children: _jsxs(Stack, { direction: "horizontal", children: [_jsx("div", { children: _jsxs(FilterBtn, { children: [_jsx("img", { src: Filter, alt: "Filter icon" }), " Filter"] }) }), _jsxs(Stack, { direction: "horizontal", children: [_jsx(BlinkingDot, { children: "\u2022" }), _jsx(StyledBlikingText, { children: "Active" })] })] }) }), _jsx(Col, { lg: 8, md: 7, xs: 10, children: _jsxs(ResponsiveContainer, { children: [_jsx(Form, { style: { paddingRight: '10px' }, children: _jsxs(StyledInputGroup, { children: [_jsx(InputGroup.Text, { children: _jsx("img", { height: 24, width: 24, src: Search, alt: "search icon" }) }), _jsx(Form.Control, { type: "search", placeholder: "Search ID or information", "aria-label": "Search ID or information", width: 300 })] }) }), _jsxs(PrifileFilterContainer, { children: [_jsxs(StyledDropdown, { className: "mr-3", "data-bs-theme": "dark", children: [_jsx(Dropdown.Toggle, { id: "dropdown-button-dark-example1", variant: "secondary", children: selectedOption }), _jsx(Dropdown.Menu, { children: DropdownList.map(function (item, index) { return (_jsx(Dropdown.Item, { active: item.isActive, onClick: function () { return handleSelect(item.option); }, children: item.option }, index)); }) })] }), _jsxs(FilterSwitch, { children: [_jsx(FilterDiv, { style: {
                                                backgroundColor: isGridView ? "#242424" : "transparent",
                                            }, children: _jsx(FilterIcon, { src: GridIcon, alt: "Grid View", onClick: toggleGridView, style: {
                                                    width: 24,
                                                    height: 24,
                                                    cursor: "pointer",
                                                    filter: isGridView ? "none" : "grayscale(1)",
                                                } }) }), _jsx(FilterDiv, { style: {
                                                backgroundColor: !isGridView ? "#242424" : "transparent",
                                            }, children: _jsx(FilterIcon, { src: ListIcon, alt: "List View", onClick: toggleListView, style: {
                                                    width: 24,
                                                    height: 24,
                                                    cursor: "pointer",
                                                    filter: !isGridView ? "none" : "grayscale(1)",
                                                } }) })] })] })] }) })] }));
};
