export var yellowPointsArr = [
    {
        x1: 10.7349,
        y1: 26.4663,
        x2: 13.126,
        y2: 23.6166
    },
    {
        x1: 12.5249,
        y1: 32.4740,
        x2: 17.0009,
        y2: 27.9981
    },
    {
        x1: 18.8628,
        y1: 34.1001,
        x2: 21.9713,
        y2: 31.5827
    }
];
export var crossLinePoints = {
    x1: 15.5,
    y1: 13,
    x2: 33,
    y2: 32,
};
