var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import { HeaderButtonContainer, HeaderContainer, HeaderLanguageButton, HeaderLanguageIcon, HeaderLaunchButton, HeaderLaunchIcon, HeaderDropdown, HeaderDropdownItem, HeaderFullScreenContent, HeaderBurgerMenu, HeaderBurgerContainer, HeaderBurgerMenuContent, VisibilityBurgerContainer, VisibilityBurgerLaunchBtn, HeaderBurgerMenuBottomContent, VisibilityInBurgerLaunchBtn, LanguageModalOverlay, LanguageModalContent, ModalCloseButton } from "../../assets/styles/header/header.style";
import { LocalizeLanguage } from '../../const/localizeLanguage/localizeLanguage';
import HeaderNavigation from "../headerNavigation/HeaderNavigation";
import Logo from "../logo/Logo";
import axios from 'axios';
import { useLocaleStore } from '../../store/localeStore';
import { useNavigate } from 'react-router-dom';
var Header = function (_a) {
    var _b, _c;
    var currentLocale = _a.currentLocale, onLocaleChange = _a.onLocaleChange;
    var _d = useState(false), isDropdownOpen = _d[0], setIsDropdownOpen = _d[1];
    var _e = useState(false), isModalOpen = _e[0], setIsModalOpen = _e[1];
    var _f = useState(currentLocale), selectedLanguage = _f[0], setSelectedLanguage = _f[1];
    var _g = useState(false), isBurgerOpen = _g[0], setIsBurgerOpen = _g[1];
    var _h = useState({}), localeContent = _h[0], setLocaleContent = _h[1];
    var navigate = useNavigate();
    var content = localeContent[currentLocale];
    useEffect(function () { return setSelectedLanguage(currentLocale); }, [currentLocale]);
    var toggleDropdown = function () { return setIsDropdownOpen(!isDropdownOpen); };
    var toggleBurger = function () { return setIsBurgerOpen(!isBurgerOpen); };
    var openModal = function () { return setIsModalOpen(true); };
    var closeModal = function () { return setIsModalOpen(false); };
    var selectLanguage = function (locale) {
        setSelectedLanguage(locale);
        onLocaleChange(locale);
        setIsDropdownOpen(false);
        setIsModalOpen(false);
    };
    var dropdownRef = useRef(null);
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return function () { return document.removeEventListener('mousedown', handleClickOutside); };
    }, []);
    useEffect(function () {
        document.body.style.overflow = isBurgerOpen ? 'hidden' : '';
        return function () {
            document.body.style.overflow = '';
        };
    }, [isBurgerOpen]);
    var locale = useLocaleStore(function (state) { return state.locale; });
    useEffect(function () {
        var fetchLocaleContent = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get("".concat(process.env.REACT_APP_API_URL, "/localization/").concat(locale))];
                    case 1:
                        response = _a.sent();
                        setLocaleContent(response.data);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching localized content:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchLocaleContent();
    }, [locale]);
    return (_jsxs(HeaderContainer, { children: [_jsx(Logo, {}), _jsx(HeaderFullScreenContent, { children: _jsx(HeaderNavigation, {}) }), _jsxs(HeaderBurgerContainer, { children: [_jsx(VisibilityBurgerContainer, { children: _jsxs(HeaderButtonContainer, { children: [_jsxs(HeaderLanguageButton, { onClick: toggleDropdown, children: [((_b = LocalizeLanguage.find(function (item) { return item.code === selectedLanguage; })) === null || _b === void 0 ? void 0 : _b.full) || selectedLanguage, _jsx(HeaderLanguageIcon, {})] }), isDropdownOpen && (_jsx(HeaderDropdown, { ref: dropdownRef, children: LocalizeLanguage.map(function (item) { return (_jsx(HeaderDropdownItem, { onClick: function () { return selectLanguage(item.code); }, style: {
                                            color: selectedLanguage === item.code ? '#95949F' : '#FFFFFF'
                                        }, children: item.full }, item.code)); }) }))] }) }), _jsx(VisibilityBurgerLaunchBtn, { children: _jsxs(HeaderLaunchButton, { className: 'launchButton', onClick: function () { return navigate('/auth'); }, children: [localeContent.launch_button, _jsx(HeaderLaunchIcon, {})] }) }), _jsx(HeaderBurgerMenu, { "$isOpen": isBurgerOpen, onClick: toggleBurger }), isBurgerOpen && (_jsxs(HeaderBurgerMenuContent, { children: [_jsx(HeaderNavigation, {}), _jsxs(HeaderBurgerMenuBottomContent, { children: [_jsx(HeaderButtonContainer, { children: _jsxs(HeaderLanguageButton, { onClick: openModal, children: [((_c = LocalizeLanguage.find(function (item) { return item.code === selectedLanguage; })) === null || _c === void 0 ? void 0 : _c.full) || selectedLanguage, _jsx(HeaderLanguageIcon, {})] }) }), _jsx(VisibilityInBurgerLaunchBtn, { children: _jsxs(HeaderLaunchButton, { onClick: function () { return navigate('/auth'); }, className: 'launchButton', children: [localeContent.launch_button, _jsx(HeaderLaunchIcon, {})] }) })] })] }))] }), isModalOpen && (_jsx(LanguageModalOverlay, { children: _jsxs(LanguageModalContent, { children: [_jsx(ModalCloseButton, { onClick: closeModal, children: "X" }), LocalizeLanguage.map(function (item) { return (_jsx(HeaderDropdownItem, { onClick: function () { return selectLanguage(item.code); }, style: {
                                color: selectedLanguage === item.code ? '#95949F' : '#FFFFFF'
                            }, children: item.full }, item.code)); })] }) }))] }));
};
export default Header;
