var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import { LogInContainer, LogInTitle, SpaceBackground, VerifyText } from "../../../assets/styles/logIn.style";
import { StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import { Box, BoxContainer, ContainerKYCBtn, FormContainer, HiddenInput, Instructions, UploadBox, UploadSection } from "../../../assets/styles/kycStyle";
import ImgIcon from '../../../assets/img/Img-icon.svg';
import { BackArrow } from "../../../components/authComponents/BackButton";
import { useNavigate } from "react-router-dom";
import { useKYCStore } from "../../../store/user/setUserKYC";
import { NavigateBtn } from "../../../components/authComponents/NextBtn";
import { Alert } from "../../../components/authComponents/Alert";
import { FormattedMessage } from "react-intl";
import { kycInfo } from "../../../const/kycRequirements/kycInfo";
export var KYCDoc = function () {
    var _a = useState({ front: null, back: null }), images = _a[0], setImages = _a[1];
    var _b = useState(null), fileError = _b[0], setFileError = _b[1];
    var inputRefs = { front: useRef(null), back: useRef(null) };
    var navigate = useNavigate();
    var _c = useKYCStore(), userKYCObj = _c.userKYCObj, addData = _c.addData;
    useEffect(function () {
        if (!userKYCObj.document && !userKYCObj.documentCountry) {
            navigate("/kyc-select");
        }
    }, [userKYCObj, navigate]);
    var handleUploadClick = function (side) { var _a; return (_a = inputRefs[side].current) === null || _a === void 0 ? void 0 : _a.click(); };
    var handleFileChangeWrapper = function (event, side) {
        var _a;
        var file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            var validTypes = ["image/png", "image/jpeg", "image/jpg"];
            if (!validTypes.includes(file.type)) {
                setFileError("Invalid file type. Please upload a PNG, JPEG, or JPG image.");
                return;
            }
            setFileError(null);
            var reader_1 = new FileReader();
            reader_1.onloadend = function () {
                var fileData = reader_1.result;
                setImages(function (prev) {
                    var _a;
                    return (__assign(__assign({}, prev), (_a = {}, _a[side] = fileData, _a)));
                });
            };
            reader_1.readAsDataURL(file);
        }
    };
    var renderUploadBox = function (side, label) { return (_jsxs(Box, { children: [_jsx("span", { children: _jsx(FormattedMessage, { id: label }) }), _jsxs(UploadBox, { onClick: function () { return handleUploadClick(side); }, image: images[side] || undefined, children: [!images[side] && (_jsxs(BoxContainer, { children: [_jsx("img", { src: ImgIcon, alt: "img-icon" }), _jsxs("span", { style: { fontSize: "12px" }, children: [_jsx(FormattedMessage, { id: "picture_card_text" }), _jsx("br", {}), _jsxs("span", { style: { color: "#FFB801", fontSize: "12px" }, children: [_jsx(FormattedMessage, { id: "picture_card_signed_text" }), " "] })] })] })), _jsx(HiddenInput, { type: "file", accept: "image/png, image/jpeg, image/jpg", ref: inputRefs[side], onChange: function (e) { return handleFileChangeWrapper(e, side); } })] })] })); };
    var validateForm = function () {
        return images.front !== null && images.back !== null;
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            e.preventDefault();
            if (validateForm()) {
                addData('docImageFront', images.front);
                addData('docImageBack', images.back);
                navigate('/kyc-person');
            }
            else {
                setFileError("Both sides of the ID card are required.");
            }
            return [2 /*return*/];
        });
    }); };
    return (_jsxs(SpaceBackground, { children: [_jsx(LogInContainer, { children: _jsx(StyledRow, { "$justifyContent": "center", style: { height: "100%" }, children: _jsx(StyledCol, { "$justifyContent": "center", "$lg": 7, "$md": 12, children: _jsxs(FormContainer, { onSubmit: handleSubmit, children: [_jsxs("div", { style: { justifyContent: "start" }, children: [_jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "kyc_info_title" }) }), _jsxs(VerifyText, { children: [_jsx(FormattedMessage, { id: "kyc_info_description1" }), " ", _jsx("br", {}), " ", _jsx(FormattedMessage, { id: "kyc_info_description2" })] })] }), _jsxs(UploadSection, { children: [renderUploadBox("front", "picture_box_front"), renderUploadBox("back", "picture_box_back")] }), _jsx(Instructions, { children: kycInfo.map(function (_a) {
                                        var messageId = _a.messageId, defaultMessage = _a.defaultMessage;
                                        return (_jsx("li", { children: _jsx(FormattedMessage, { id: messageId, defaultMessage: defaultMessage }) }, messageId));
                                    }) }), _jsxs(ContainerKYCBtn, { children: [_jsx(BackArrow, {}), _jsx(NavigateBtn, { link: "/kyc-person", validate: validateForm(), onClick: handleSubmit, width: "50%" })] })] }) }) }) }), fileError && _jsx(Alert, { message: fileError, onClose: function () { return setFileError(null); }, isSuccess: false })] }));
};
