var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import Select from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import { AuthInputLabel, FlagContainer, FormFlagPicker } from '../../assets/styles/logIn.style';
import { options } from '../../const/locales/options';
import { FormattedMessage } from 'react-intl';
export var customStyles = {
    control: function (provided) { return (__assign(__assign({}, provided), { borderRadius: '16px', backgroundColor: '#1B1B27', padding: '7px 10px', border: 'none', boxShadow: 'none' })); },
    menu: function (provided) { return (__assign(__assign({}, provided), { backgroundColor: '#1B1B27', borderRadius: '16px', marginTop: 0, overflow: 'hidden' })); },
    option: function (provided, state) { return (__assign(__assign({}, provided), { backgroundColor: state.isFocused ? '#42424b' : '#1B1B27', color: '#fff', padding: '10px', '&:hover': {
            backgroundColor: '#34343b',
        } })); },
    singleValue: function (provided) { return (__assign(__assign({}, provided), { color: '#fff' })); },
};
export var LanguagePicker = function (_a) {
    var textOptionId = _a.textOptionId, onCountrySelect = _a.onCountrySelect;
    var _b = useState(null), selectedOption = _b[0], setSelectedOption = _b[1];
    var handleChange = function (newValue) {
        setSelectedOption(newValue);
        if (newValue) {
            onCountrySelect(newValue.value);
        }
    };
    return (_jsxs(FormFlagPicker, { children: [_jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: textOptionId }) }), _jsx(Select, { options: options, value: selectedOption, onChange: handleChange, formatOptionLabel: function (e) { return (_jsxs(FlagContainer, { children: [_jsx(ReactCountryFlag, { countryCode: e.value, svg: true, style: {
                                marginRight: 10,
                            } }), e.label] })); }, styles: customStyles })] }));
};
