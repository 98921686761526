export var NftContent = [
    {
        name: 'Test',
        img: 'https://assets.pinterest.com/ext/embed.html?id=26951297764305160'
    },
    {
        name: 'Test',
        img: 'https://assets.pinterest.com/ext/embed.html?id=26951297764305160'
    },
    {
        name: 'Test',
        img: 'https://assets.pinterest.com/ext/embed.html?id=26951297764305160'
    },
    {
        name: 'Test',
        img: 'https://assets.pinterest.com/ext/embed.html?id=26951297764305160'
    },
    {
        name: 'Test',
        img: 'https://assets.pinterest.com/ext/embed.html?id=26951297764305160'
    },
    {
        name: 'Test',
        img: 'https://assets.pinterest.com/ext/embed.html?id=26951297764305160'
    },
];
