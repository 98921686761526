var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Radio } from "@mui/material";
export var FormContainer = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n    justify-content: start;\n    padding: 50px;\n    color: white;\n\n    @media (max-width: 425px) {\n        padding: 20px;\n        justify-content: center;\n        align-items: center;\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n    justify-content: start;\n    padding: 50px;\n    color: white;\n\n    @media (max-width: 425px) {\n        padding: 20px;\n        justify-content: center;\n        align-items: center;\n    }\n"])));
export var FormTitle = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 34px;\n    font-weight: 700;\n    text-align: center;\n    margin-bottom: 40px;\n\n    @media (max-width: 425px) {\n        font-size: 24px; \n        margin-bottom: 20px;\n    }\n"], ["\n    font-size: 34px;\n    font-weight: 700;\n    text-align: center;\n    margin-bottom: 40px;\n\n    @media (max-width: 425px) {\n        font-size: 24px; \n        margin-bottom: 20px;\n    }\n"])));
export var UploadSection = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    gap: 30px;\n    margin-top: 30px;\n    margin-bottom: 20px;\n    font-size: 12px;\n    font-weight: 400;\n    font-family: \"Kodchasan\", sans-serif;\n\n    @media (max-width: 425px) {\n        flex-direction: column; \n        gap: 15px;\n    }\n"], ["\n    display: flex;\n    justify-content: space-between;\n    gap: 30px;\n    margin-top: 30px;\n    margin-bottom: 20px;\n    font-size: 12px;\n    font-weight: 400;\n    font-family: \"Kodchasan\", sans-serif;\n\n    @media (max-width: 425px) {\n        flex-direction: column; \n        gap: 15px;\n    }\n"])));
export var UploadBox = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 213px;\n    height: 132px;\n    border: 2px dashed #565656;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 16px;\n    color: #fff;\n    cursor: pointer;\n    transition: border-color 0.3s ease;\n    background-size: cover;\n    border-radius: 16px;\n    background-position: center;\n    background-color: #1B1B27;\n    background-image: ", ";\n\n    &:hover {\n        border-color: orange;\n    }\n\n    @media (max-width: 425px) {\n        width: 180px; \n        height: 100px;\n    }\n"], ["\n    width: 213px;\n    height: 132px;\n    border: 2px dashed #565656;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 16px;\n    color: #fff;\n    cursor: pointer;\n    transition: border-color 0.3s ease;\n    background-size: cover;\n    border-radius: 16px;\n    background-position: center;\n    background-color: #1B1B27;\n    background-image: ", ";\n\n    &:hover {\n        border-color: orange;\n    }\n\n    @media (max-width: 425px) {\n        width: 180px; \n        height: 100px;\n    }\n"])), function (_a) {
    var image = _a.image;
    return (image ? "url(".concat(image, ")") : "none");
});
export var HiddenInput = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: none;\n"], ["\n    display: none;\n"])));
export var Instructions = styled.ul(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: #ffffff;\n    margin-top: 30px;\n    padding: 0;\n\n    li {\n        margin-bottom: 10px;\n        font-size: 14px;\n        font-weight: 600;\n        font-family: \"Kodchasan\", sans-serif;\n    }\n\n    @media (max-width: 425px) {\n        font-size: 12px;\n\n        li {\n            margin-left: 10px;\n            font-size: 12px;\n        }\n    }\n"], ["\n    color: #ffffff;\n    margin-top: 30px;\n    padding: 0;\n\n    li {\n        margin-bottom: 10px;\n        font-size: 14px;\n        font-weight: 600;\n        font-family: \"Kodchasan\", sans-serif;\n    }\n\n    @media (max-width: 425px) {\n        font-size: 12px;\n\n        li {\n            margin-left: 10px;\n            font-size: 12px;\n        }\n    }\n"])));
export var NextButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    width: 280px;\n    height: 60px;\n    background-color: #FFB801;\n    font-family: \"Kodchasan\", sans-serif;\n    font-weight: 700;\n    font-size: 14px;\n    color: #13131e;\n    padding: 10px 40px;\n    border: none;\n    border-radius: 20px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    text-align: center;\n\n    &:hover {\n      background-color: #FFE247;\n      box-shadow: 0 8px 14px -4px rgba(255, 231, 12, 0.49);\n    }\n\n    &:active {\n      box-shadow: 0 0 0 0 rgba(255, 231, 12, 0.49);\n      color: #000004;\n      background-color: #E29500;\n    }\n\n    @media (max-width: 425px) {\n        width: 220px; \n        height: 50px;\n        font-size: 14px; \n    }\n"], ["\n    width: 280px;\n    height: 60px;\n    background-color: #FFB801;\n    font-family: \"Kodchasan\", sans-serif;\n    font-weight: 700;\n    font-size: 14px;\n    color: #13131e;\n    padding: 10px 40px;\n    border: none;\n    border-radius: 20px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    text-align: center;\n\n    &:hover {\n      background-color: #FFE247;\n      box-shadow: 0 8px 14px -4px rgba(255, 231, 12, 0.49);\n    }\n\n    &:active {\n      box-shadow: 0 0 0 0 rgba(255, 231, 12, 0.49);\n      color: #000004;\n      background-color: #E29500;\n    }\n\n    @media (max-width: 425px) {\n        width: 220px; \n        height: 50px;\n        font-size: 14px; \n    }\n"])));
export var ContainerKYCBtn = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: flex;\n    gap: 20px;\n    margin-top: 50px;\n    width: 100%;\n\n    @media (max-width: 425px) {\n        gap: 10px;\n    }\n"], ["\n    display: flex;\n    gap: 20px;\n    margin-top: 50px;\n    width: 100%;\n\n    @media (max-width: 425px) {\n        gap: 10px;\n    }\n"])));
export var BoxContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    gap: 10px;\n\n    @media (max-width: 425px) {\n        gap: 5px; \n    }\n"], ["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    gap: 10px;\n\n    @media (max-width: 425px) {\n        gap: 5px; \n    }\n"])));
export var Box = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    justify-content: center;\n    align-items: center;\n\n    @media (max-width: 425px) {\n        gap: 5px;\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    justify-content: center;\n    align-items: center;\n\n    @media (max-width: 425px) {\n        gap: 5px;\n    }\n"])));
export var RadioContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    margin-top: 20px;\n    gap: 10px;\n\n    @media (max-width: 425px) {\n        gap: 5px; \n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    margin-top: 20px;\n    gap: 10px;\n\n    @media (max-width: 425px) {\n        gap: 5px; \n    }\n"])));
export var OptionLabel = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n    color: white;\n    gap: 10px;\n    margin-left: 10px;\n\n    svg {\n        margin-right: 10px;\n    }\n\n    @media (max-width: 425px) {\n        font-size: 14px; \n        gap: 5px;\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n    color: white;\n    gap: 10px;\n    margin-left: 10px;\n\n    svg {\n        margin-right: 10px;\n    }\n\n    @media (max-width: 425px) {\n        font-size: 14px; \n        gap: 5px;\n    }\n"])));
export var CustomRadioButton = styled(Radio)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    color: #959595 !important;\n\n    &.Mui-checked {\n        color: orange !important;\n    }\n"], ["\n    color: #959595 !important;\n\n    &.Mui-checked {\n        color: orange !important;\n    }\n"])));
export var RadioWrapper = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 400px;\n    height: 50px;\n    background-color: #1B1B27;\n    margin-top: 10px;\n    border-radius: 16px;\n    border: ", ";\n    transition: border 0.3s ease;\n\n    font-weight: 600;\n    font-size: 14px;\n    font-family: \"Kodchasan\", sans-serif;\n\n    @media (max-width: 425px) {\n        width: 300px; \n        height: 45px; \n    }\n"], ["\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 400px;\n    height: 50px;\n    background-color: #1B1B27;\n    margin-top: 10px;\n    border-radius: 16px;\n    border: ", ";\n    transition: border 0.3s ease;\n\n    font-weight: 600;\n    font-size: 14px;\n    font-family: \"Kodchasan\", sans-serif;\n\n    @media (max-width: 425px) {\n        width: 300px; \n        height: 45px; \n    }\n"])), function (_a) {
    var $isSelected = _a.$isSelected;
    return ($isSelected ? '2px solid orange' : '2px solid transparent');
});
export var SmallText = styled.p(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    font-size: 16px;\n    color: #959595;\n\n    @media (max-width: 425px) {\n        font-size: 14px; \n    }\n"], ["\n    font-size: 16px;\n    color: #959595;\n\n    @media (max-width: 425px) {\n        font-size: 14px; \n    }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
