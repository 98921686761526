export var pl_locale = {
    next_btn: 'Dalej',
    have_an_account: 'Masz już konto?',
    log_in: 'Zaloguj się',
    sign_up: 'Zarejestruj się',
    auth_title1: 'Witaj',
    auth_title2: 'w Quanta',
    line_text: 'lub',
    sign_up_with_google: 'Zarejestruj się przez Google',
    sign_up_with_apple: 'Zarejestruj się przez Apple',
    log_in_title: 'Witaj ponownie',
    email_phone_input_label: 'Email/Numer telefonu',
    password_input_label: 'Hasło',
    didt_remember_password: 'Nie pamiętasz hasła?',
    change_password_title: 'Nowe hasło',
    password_check_input_label: 'Sprawdź hasło',
    reset_password_title: 'Zresetuj hasło',
    email_input_label: 'Email',
    email_verification_title: 'Weryfikacja emaila',
    email_verification_description: 'Wprowadź 6-cyfrowy kod weryfikacyjny, który został wysłany na',
    email_code_title: 'Kod emaila',
    wait_code: 'Czekaj, aby wysłać kod ponownie',
    not_get_code: 'Nie otrzymałeś kodu?',
    phone_verification_title: 'Weryfikacja telefonu',
    phone_verification_text1: 'Wprowadź 6-cyfrowy kod weryfikacyjny',
    phone_verification_text2: 'który został wysłany na Twój numer telefonu',
    input_phone_label: 'Telefon',
    input_code_label: 'Kod',
    creating_account_title: 'Tworzenie konta',
    terms_and_policy1: 'Tworząc konto',
    terms_and_policy2: ', zgadzam się z',
    terms_and_policy3: ' warunkami ',
    terms_and_policy4: 'Regulaminu',
    terms_and_policy5: ' i ',
    terms_and_policy6: 'Polityką prywatności',
    email_verification_code1: 'Proszę wprowadzić 6-cyfrowy kod weryfikacyjny',
    email_verification_code2: 'który został wysłany na',
    email_verification_code3: 'Kod jest ważny przez 30 minut.',
    email_verification_label: 'Kod weryfikacyjny e-mail',
    home_adress_title: 'Adres zamieszkania',
    home_adress_description: 'Upewnij się, że to twój aktualny adres zamieszkania.',
    full_adress_label: 'Pełny adres',
    postal_code_label: 'Kod pocztowy',
    city_label: 'Miasto',
    region_label: 'Województwo',
    password_creation_title: 'Hasło',
    password_check_label: 'Sprawdź hasło',
    personal_information_title: 'Informacje osobiste',
    personal_information_description1: 'Proszę podać następujące informacje, jak w',
    personal_information_description2: 'twoim paszporcie lub dowodzie tożsamości.',
    full_name_label: 'Pełne imię',
    data_about_user: 'Dane muszą zgadzać się z danymi w paszporcie lub dowodzie tożsamości.',
    date_birth_label: 'Data urodzenia',
    phone_verification_code_label: 'Kod weryfikacji telefonu',
    residensy_title: 'Zweryfikujmy cię',
    residensy_title_description: 'Najpierw wybierz swój kraj zamieszkania.',
    residency_text: 'Upewnij się, że twój kraj zamieszkania jest poprawny.',
    residency_option: "Miejsce zamieszkania",
    citizenship_option: "Obywatelstwo",
    back_btn_text: "Wróć",
    document_select_title: 'Weryfikacja dokumentu',
    document_select_description: 'Wybierz kraj/region wydania dokumentu.',
    document_select_warning: 'Użyj ważnego dokumentu wydanego przez rząd',
    document_select_text1: 'Tylko poniższe dokumenty ',
    document_select_text2: 'będą akceptowane; wszystkie inne dokumenty będą ',
    document_select_text3: 'odrzucone.',
    id_card_text: 'Dowód osobisty',
    passport_text: 'Paszport',
    kyc_info_title: 'Weryfikacja dokumentu',
    kyc_info_description1: 'Zrób zdjęcie obu stron swojego dokumentu wydanego ',
    kyc_info_description2: 'przez rząd.',
    reqiurements_kyc1: 'Dokument jest ważny',
    reqiurements_kyc2: 'To jest oryginalny dokument, nie skan lub kopia',
    reqiurements_kyc3: 'Usuń wszelkie osłony lub pokrowce, aby uniknąć odbić lub rozmazania',
    reqiurements_kyc4: 'Połóż dokument na jednolitym tle',
    reqiurements_kyc5: 'Dokument powinien być trzymany prosto, nie przechylony ani obrócony.',
    picture_card_text: 'Upuść zdjęcie tutaj lub wybierz',
    picture_card_signed_text: 'Kliknij, aby przeglądać',
    picture_box_front: 'Przód dowodu osobistego',
    picture_box_back: 'Tył dowodu osobistego',
    kyc_person_label: 'Weryfikacja tożsamości',
    kyc_person_description: 'Zrób zdjęcie siebie z dokumentem w rękach.',
    photo_label: 'Twoje zdjęcie',
    person_requirements1: 'Obraz musi być ostry, bez rozmycia lub pikselizacji.',
    person_requirements2: 'Twarz użytkownika i dokument muszą być całkowicie widoczne i nie przycięte.',
    person_requirements3: 'Informacje na dokumencie muszą być wyraźnie widoczne i nie zasłonięte palcami.',
    person_requirements4: 'Dokument powinien być trzymany prosto, nie przechylony ani obrócony.',
    verify_btn: 'Zweryfikuj',
    verify_main_text: 'Teraz zweryfikujmy Twoją tożsamość, aby korzystać ze wszystkich funkcji Quanta.',
    acccount_created_title: 'konto utworzone',
    account_verified_title: 'konto zweryfikowane',
    registartion_success_title: 'Rejestracja zakończona sukcesem',
    auth_success_title: 'Autoryzacja zakończona sukcesem',
    auth_login: 'Teraz korzystaj ze wszystkich funkcji Quanta',
    kyc_text: 'Zostaniesz powiadomiony o wynikach później.',
    kyc_title: 'KYC wysłane do weryfikacji',
    not_now: 'Nie teraz',
    password_requirements1: "Co najmniej 8 znaków",
    password_requirements2: "Co najmniej 1 cyfra",
    password_requirements3: "Co najmniej 1 wielka litera",
    password_requirements4: "Hasła muszą być zgodne",
    apple_btn: "Zarejestruj się za pomocą Apple",
    adress_palceholder: "Adres",
    first_name_placeholder: "Imię",
    last_name_placeholder: "Nazwisko",
};
