var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from "react";
import { ArrowVisibility, DropDownButton, DropDownButtonIcon, DropDownButtonText, HeaderNavigationContainer, } from "../../assets/styles/headerNavigation/headerNavigation.style";
import { DropdowmLink, HeaderBurgerList, HeaderDropdown, HeaderDropdownItem, } from "../../assets/styles/header/header.style";
import { useLocaleStore } from "../../store/localeStore";
import axios from "axios";
var HeaderNavigation = function () {
    var _a = useState(null), openDropdownIndex = _a[0], setOpenDropdownIndex = _a[1];
    var _b = useState([]), navItems = _b[0], setNavArr = _b[1];
    var _c = useState({}), localeContent = _c[0], setLocaleContent = _c[1];
    var dropdownRef = useRef(null);
    var locale = useLocaleStore(function (state) { return state.locale; });
    useEffect(function () {
        var fetchLocaleContent = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!locale) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.get("".concat(process.env.REACT_APP_API_URL, "/localization/").concat(locale))];
                    case 2:
                        response = _a.sent();
                        setLocaleContent(response.data.nav_titles);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Error fetching localized content:", error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchLocaleContent();
    }, [locale]);
    var toggleDropdown = function (index) {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };
    var fetchNavItems = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("".concat(process.env.REACT_APP_API_URL, "/nav-items"))];
                case 1:
                    response = _a.sent();
                    setNavArr(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error("Error fetching navigation items:", error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchNavItems();
    }, []);
    var handleClickOutside = function (event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpenDropdownIndex(null);
        }
    };
    useEffect(function () {
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (_jsxs(HeaderNavigationContainer, { ref: dropdownRef, children: [navItems.map(function (item, index) {
                var _a, _b;
                return (_jsxs(DropDownButton, { onClick: function () { return toggleDropdown(index); }, children: [_jsx(DropDownButtonText, { className: "dropDownButtonText", children: localeContent[item.title] || item.title }), openDropdownIndex === index && (_jsx(HeaderDropdown, { children: (_a = item.dropItems) === null || _a === void 0 ? void 0 : _a.map(function (dropItem, dropIndex) { return (_jsx(HeaderDropdownItem, { children: dropItem.link ? (_jsx(DropdowmLink, { to: dropItem.link, children: localeContent[dropItem.title] || dropItem.title })) : (_jsxs("span", { children: [localeContent[dropItem.title] || dropItem.title, " "] })) }, dropIndex)); }) })), _jsx(HeaderBurgerList, { children: (_b = item.dropItems) === null || _b === void 0 ? void 0 : _b.map(function (dropItem, dropIndex) { return (_jsx(HeaderDropdownItem, { children: _jsx(DropdowmLink, { style: { flexDirection: "column" }, to: "/404", children: localeContent[dropItem.title] || dropItem.title }) }, dropIndex)); }) }), item.arrow && (_jsx(ArrowVisibility, { children: _jsx(DropDownButtonIcon, { className: "dropDownButtonIcon" }) }))] }, index));
            }), _jsx(DropdowmLink, { to: "/blog", children: localeContent.blog }), _jsx(DropdowmLink, { to: "/learn", children: localeContent.learn })] }));
};
export default HeaderNavigation;
