import { useState } from "react";
export var useFileUpload = function (acceptedFormats) {
    var _a = useState([]), files = _a[0], setFiles = _a[1];
    var _b = useState({}), fileURLs = _b[0], setFileURLs = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var handleFileChange = function (e, fieldName) {
        var newFiles = e.target.files ? Array.from(e.target.files) : [];
        var invalidFiles = newFiles.filter(function (file) { return !acceptedFormats.includes(file.type); });
        if (invalidFiles.length > 0) {
            setError("Invalid file type uploaded.");
            return;
        }
        setFiles(newFiles);
        var newFileURLs = newFiles.reduce(function (acc, file) {
            acc[file.name] = URL.createObjectURL(file);
            return acc;
        }, {});
        setFileURLs(newFileURLs);
    };
    var clearError = function () { return setError(null); };
    return { files: files, fileURLs: fileURLs, error: error, handleFileChange: handleFileChange, clearError: clearError };
};
