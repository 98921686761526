import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { WagmiProvider, createConfig } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConnectKitProvider, getDefaultConfig } from 'connectkit';
import { arbitrum, mainnet, optimism, polygon } from 'viem/chains';
import Avatar from './Avatar';
export var config = createConfig(getDefaultConfig({
    appName: 'ConnectKit Vite demo',
    walletConnectProjectId: "6c89183532d5bc0feec98d74c5345012",
    chains: [mainnet, polygon, optimism, arbitrum],
}));
var queryClient = new QueryClient();
export var Web3Provider = function (_a) {
    var children = _a.children;
    return (_jsx(WagmiProvider, { config: config, children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(ConnectKitProvider, { options: {
                    customAvatar: Avatar,
                }, children: children }) }) }));
};
