export var Requirements = function (password, confirmPassword) { return [
    {
        title: 'password_requirements1',
        check: function () { return password.length >= 8; },
        defaultMessage: 'At least 8 symbols',
    },
    {
        title: 'password_requirements2',
        check: function () { return /\d/.test(password); },
        defaultMessage: 'At least 1 number',
    },
    {
        title: 'password_requirements3',
        check: function () { return /[A-ZА-ЯЁІЇЄҐ]/.test(password); },
        defaultMessage: 'At least 1 capital letter',
    },
    {
        title: 'password_requirements4',
        check: function () { return password === confirmPassword; },
        defaultMessage: 'Passwords must match',
    },
]; };
