import { jsx as _jsx } from "react/jsx-runtime";
import AvatarImg from '../../assets/img/avatarUser.svg';
var Avatar = function () {
    return (_jsx("div", { style: {
            overflow: "hidden",
            borderRadius: 10,
            height: "100%",
            width: "100%",
            // background: generateColorFromAddress(address), // your function here
        }, children: _jsx("img", { src: AvatarImg, alt: "avatar", width: "100%", height: "100%" }) }));
};
export default Avatar;
