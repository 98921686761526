import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LogoContainer, LogoLink, LogoImg, LogoText, LogoTitle, LogoSubTitle } from '../../assets/styles/logo/logo.style';
var Logo = function () {
    var _a = useState(''), imageUrl = _a[0], setImageUrl = _a[1];
    useEffect(function () {
        axios.get("".concat(process.env.REACT_APP_API_URL, "/images/logo.webp"), { responseType: 'blob' })
            .then(function (response) {
            var imageUrl = URL.createObjectURL(response.data);
            setImageUrl(imageUrl);
        })
            .catch(function (error) { return console.error('Error fetching logo image:', error); });
    }, []);
    return (_jsx(LogoContainer, { children: _jsxs(LogoLink, { to: '/', children: [_jsx(LogoImg, { style: { backgroundImage: "url(".concat(imageUrl, ")") } }), _jsxs(LogoText, { children: [_jsx(LogoTitle, { children: " QUANTA " }), _jsx(LogoSubTitle, { children: " Technology " })] })] }) }));
};
export default Logo;
