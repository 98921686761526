export var isLongEnough = function (password, confirmPassword) {
    var isPasswordLongEnough = password.length >= 8;
    var isConfirmPasswordLongEnough = confirmPassword ? confirmPassword.length >= 8 : true;
    return isPasswordLongEnough && isConfirmPasswordLongEnough;
};
export var hasNumber = function (password) { return /\d/.test(password); };
export var hasCapitalLetter = function (password) { return /[A-ZА-ЯЁІЇЄҐ]/.test(password); };
export var isEmail = function (email) { return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email); };
export var isPhone = function (phone) { return /^(?:\+?380\s?|0)([5-9][0-9]\s?[0-9]{3}\s?[0-9]{4})$/.test(phone); };
export var isEqual = function (password, confirmPassword) { return password === confirmPassword; };
