import DiscordIcon from '../../assets/img/DiscordIcon.svg';
import InstagramIcon from '../../assets/img/InstagramIcon.svg';
import TwitterIcon from '../../assets/img/TwitterIcon.svg';
export var ProfileList = [
    {
        value: '377 ETH',
        describe: 'Total Volume',
    },
    {
        value: '0,0095 ETH',
        describe: 'Floor Price',
    },
    {
        value: '0,0043 weth',
        describe: 'Best offer',
    },
    {
        value: '4%',
        describe: 'Listed',
    },
    {
        value: '274 (54%)',
        describe: 'Owners (Unique)',
    },
];
export var SotialsIcons = [
    {
        link: '/twitter',
        icon: TwitterIcon,
        name: 'Twitter'
    },
    {
        link: '/discord',
        icon: DiscordIcon,
        name: 'Discord'
    },
    {
        link: '/instagram',
        icon: InstagramIcon,
        name: 'Instagram'
    }
];
export var ProfileData = [
    {
        items: 482,
        createdOn: '19 january 2019',
        earnings: '2%',
    }
];
export var DropdownList = [
    {
        option: 'Price low to hight',
        link: '/action1',
        isActive: true
    },
    {
        option: 'Price high to low',
        link: '/action1',
        isActive: false
    },
    {
        option: 'Sort by data',
        link: '/action2',
        isActive: false
    },
];
