var _a;
import { LOCALES } from "../locales/locales";
import { en_locale } from "./cultures/en_locale";
import { ru_locale } from "./cultures/ru_locale";
import { ch_locale } from "./cultures/cz_locale";
import { ar_locale } from "./cultures/ar_locale";
import { he_locale } from "./cultures/he_locale";
import { it_locale } from "./cultures/it_locale";
import { pl_locale } from "./cultures/pl_locale";
import { ua_locale } from "./cultures/ua_locale";
import { fr_locale } from "./cultures/fr_locale";
import { de_locale } from "./cultures/de_locale";
import { es_locale } from "./cultures/es_locale";
export var messages = (_a = {},
    _a[LOCALES.ENGLISH] = en_locale,
    _a[LOCALES.RUSSIAN] = ru_locale,
    _a[LOCALES.SPANISH] = es_locale,
    _a[LOCALES.CZECH] = ch_locale,
    _a[LOCALES.ARABIC] = ar_locale,
    _a[LOCALES.HEBREW] = he_locale,
    _a[LOCALES.ITALIAN] = it_locale,
    _a[LOCALES.POLISH] = pl_locale,
    _a[LOCALES.UKRAINIAN] = ua_locale,
    _a[LOCALES.FRENCH] = fr_locale,
    _a[LOCALES.GERMAN] = de_locale,
    _a);
