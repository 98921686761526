export var en_locale = {
    next_btn: 'Next',
    have_an_account: 'Already have an account?',
    log_in: 'Log In',
    sign_up: 'Sign Up',
    auth_title1: 'Welcome',
    auth_title2: 'to quanta',
    line_text: 'or',
    sign_up_with_google: 'Sign up with Google',
    sign_up_with_apple: 'Sign up with Apple',
    log_in_title: 'welcome back',
    email_phone_input_label: 'Email/Phone number',
    password_input_label: 'Password',
    didt_remember_password: "Don't remember a password?",
    change_password_title: 'new password',
    password_check_input_label: 'Check password',
    reset_password_title: 'reset password',
    email_input_label: 'Email',
    email_verification_title: 'Email Verification',
    email_verification_description: 'Please enter the 6-digit verification code that was sent to',
    email_code_title: 'Email code',
    wait_code: 'Wait to resend code',
    not_get_code: 'Didn’t get a code?',
    phone_verification_title: 'phone verification',
    phone_verification_text1: 'Please enter the 6-digit verification code',
    phone_verification_text2: 'that was sent to your phone number',
    input_phone_label: 'Phone',
    input_code_label: 'Code',
    creating_account_title: 'Creating an account',
    terms_and_policy1: 'By creating an account',
    terms_and_policy2: ', I agree to',
    terms_and_policy3: ' the ',
    terms_and_policy4: 'Terms of Use',
    terms_and_policy5: ' and ',
    terms_and_policy6: 'Privacy Policy',
    email_verification_code1: 'Please enter the 6-digit verification code',
    email_verification_code2: 'that was sent to',
    email_verification_code3: 'The code is valid for 30 minutes.',
    email_verification_label: 'Email Verification Code',
    home_adress_title: 'Home Address',
    home_adress_description: 'Make sure this is your current residential address.',
    full_adress_label: 'Full address',
    postal_code_label: 'Postal Code',
    city_label: 'City',
    region_label: 'Region',
    adress_palceholder: 'Address',
    first_name_placeholder: 'First name',
    last_name_placeholder: 'Last Name',
    password_creation_title: 'Password',
    password_check_label: 'Check password',
    personal_information_title: 'personal information',
    personal_information_description1: 'Please provide the following information as shown',
    personal_information_description2: 'on your passport or identity card.',
    full_name_label: 'Full name',
    data_about_user: 'The data must match the data in the passport or identity card.',
    date_birth_label: 'Date of birth',
    phone_verification_code_label: 'Phone verification code',
    residensy_title: 'Let’s get you verified',
    residensy_title_description: 'First of all, select your residency.',
    residency_text: 'Make sure your country of residence is correct.',
    residency_option: 'Residency',
    citizenship_option: 'Citizenship',
    back_btn_text: 'Back',
    document_select_title: 'Document Verification',
    document_select_description: 'Select your document issuing country/region.',
    document_select_warning: 'Use a valid goverment-issued document',
    document_select_text1: 'Only the following documents listed below ',
    document_select_text2: ' will be accepted; all other documents will be ',
    document_select_text3: 'rejected.',
    id_card_text: 'ID Card',
    passport_text: 'Passport',
    kyc_info_title: 'document verification',
    kyc_info_description1: 'Take picture of both sides of your government-issued',
    kyc_info_description2: 'ID card.',
    reqiurements_kyc1: 'Document has not expired',
    reqiurements_kyc2: 'This is an original document, not a scan or copy',
    reqiurements_kyc3: 'Remove any card holders or covers to avoid reflections or blur',
    reqiurements_kyc4: 'Place documents against a solid-colored background',
    reqiurements_kyc5: 'The document should be held straight, not tilted or rotated.',
    picture_card_text: 'Drop your photo here or select',
    picture_card_signed_text: 'Click to browse',
    picture_box_front: 'Front of ID card',
    picture_box_back: 'Back of ID card',
    kyc_person_label: 'Personality Verification',
    kyc_person_description: 'Take picture of you and your document in your hands.',
    photo_label: 'Photo of you',
    person_requirements1: 'The image must be sharp, without any blur or pixelation.',
    person_requirements2: "Both the user's face and the document must be fully visible and not cropped.",
    person_requirements3: "The information on the document should be clearly visible and not covered by fingers.",
    person_requirements4: "The document should be held straight, not tilted or rotated.",
    verify_btn: 'Verify',
    verify_main_text: 'Now let’s verify your identification to use all features of Quanta.',
    acccount_created_title: 'account created',
    account_verified_title: 'account verified',
    registartion_success_title: 'Registration succesfull',
    auth_success_title: 'Authorization succesfull',
    auth_login: 'Now let’s use all features of Quanta',
    kyc_text: 'You will be notified of the results later.',
    kyc_title: 'KYC is sent for verification',
    not_now: 'Not now',
    password_requirements1: 'At least 8 symbols',
    password_requirements2: 'At least 1 number',
    password_requirements3: 'At least 1 capital letter',
    password_requirements4: 'Passwords must match',
    apple_btn: 'Sign Up with Apple',
};
