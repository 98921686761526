var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, Suspense } from 'react';
var Canvas = lazy(function () { return import('@react-three/fiber').then(function (module) { return ({ default: module.Canvas }); }); });
var GlobeScene = lazy(function () { return import('./GlobeScene'); });
var CanvasScene = function (props) {
    return (_jsx(_Fragment, { children: _jsxs(Canvas, { shadows: false, style: {
                width: '100%',
                height: '100%',
            }, children: [_jsx("ambientLight", { intensity: 8 }), _jsx(Suspense, { fallback: _jsx(_Fragment, {}), children: _jsx(GlobeScene, __assign({}, props)) })] }) }));
};
export default React.memo(CanvasScene);
