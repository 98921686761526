var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormContainer, LogInTitle, MainTextStyle, RegularText, SpaceBackground } from "../../../assets/styles/logIn.style";
import { PositionLayout, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import SuccessIcon from "../../../assets/img/check-mark-circle.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../../../components/authComponents/Alert";
import { useState } from "react";
// import { pathActions } from "../../../constants/pathActions/pathActions";
import { NavigateBtn } from "../../../components/authComponents/NextBtn";
import { FormattedMessage } from "react-intl";
export var pathActions = {
    '/registration/creating-password': {
        buttonText: 'verify_btn',
        mainText: 'verify_main_text',
        titleText: 'acccount_created_title',
        link: '/registration/residency',
        isNotNow: true,
    },
    '/auth/email-verify': {
        titleText: 'auth_success_title',
        buttonText: 'next_btn',
        mainText: 'auth_login',
        link: "/",
        isNotNow: false,
    },
    '/registration/email': {
        buttonText: 'verify_btn',
        mainText: 'verify_main_text',
        titleText: 'account_verified_title',
        link: '/registration/residency',
        isNotNow: true,
    },
    '/auth': {
        buttonText: 'next_btn',
        mainText: 'auth_login',
        titleText: 'auth_success_title',
        link: '/',
        isNotNow: false,
    },
    '/registration': {
        buttonText: 'verify_btn',
        mainText: 'verify_main_text',
        titleText: 'registartion_success_title',
        link: '/registration/residency',
        isNotNow: true,
    },
    '/kyc-person': {
        buttonText: 'next_btn',
        mainText: 'kyc_text',
        titleText: 'kyc_title',
        link: '/',
        isNotNow: false,
    },
};
export var SuccesfullOperation = function () {
    var _a;
    var location = useLocation();
    var navigate = useNavigate();
    var currentPath = location.pathname;
    var previousPath = ((_a = location.state) === null || _a === void 0 ? void 0 : _a.from) || currentPath;
    var _b = useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = useState(false), isSuccess = _c[0], setIsSuccess = _c[1];
    var _d = useState(false), showAlert = _d[0], setShowAlert = _d[1];
    var _e = pathActions[previousPath] || {
        buttonText: 'Verify',
        mainText: 'You have navigated from an unrecognized page.',
        titleText: "account created",
        link: '/',
        isNotNow: true,
    }, buttonText = _e.buttonText, mainText = _e.mainText, titleText = _e.titleText, link = _e.link, isNotNow = _e.isNotNow;
    var getProfileAndRedirect = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, response, responseData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem('accessToken');
                    if (!token) {
                        setErrorMessage('No access token found. Please log in.');
                        setShowAlert(true);
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/user/profile"), {
                            method: 'GET',
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer ".concat(token),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch profile.');
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    responseData = _a.sent();
                    setIsSuccess(true);
                    if (link && link.startsWith('http')) {
                        window.location.href = link;
                    }
                    else {
                        navigate(link || '/');
                    }
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    setErrorMessage("Failed to load profile. Please try again.");
                    setShowAlert(true);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(SpaceBackground, { children: _jsxs(StyledRow, { "$justifyContent": "center", style: { height: '100vh' }, children: [_jsx(StyledCol, { "$justifyContent": "center", "$lg": 10, "$md": 12, children: _jsx(StyledCol, { "$justifyContent": "center", "$lg": 10, "$md": 10, "$sm": 12, "$xs": 12, children: _jsx(FormContainer, { children: _jsxs(PositionLayout, { textAlign: "center", children: [_jsx("img", { src: SuccessIcon, alt: "Success Icon" }), _jsxs(LogInTitle, { children: [" ", _jsx(FormattedMessage, { id: titleText }), " "] }), _jsx(PositionLayout, { margin: "0px 0px 30px 0px", children: _jsx(MainTextStyle, { children: _jsx(FormattedMessage, { id: mainText }) }) }), _jsx(NavigateBtn, { link: "".concat(link), onClick: getProfileAndRedirect, width: "100%", validate: true, text: buttonText }), !isNotNow ?
                                        _jsx(PositionLayout, { margin: "15px 0px 0px 0px" }) :
                                        _jsx(PositionLayout, { margin: "15px 0px 0px 0px", children: _jsx(RegularText, { href: "/", children: _jsx(FormattedMessage, { id: "not_now" }) }) })] }) }) }) }), showAlert && (_jsx(Alert, { message: errorMessage, onClose: function () { return setShowAlert(false); }, isSuccess: isSuccess }))] }) }));
};
