import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { AuthInput, AuthInputLabel, DocumentLink, FormContainer, FormContainerText, LogInTitle, SignUpForm, StyledCheckbox, StyledFormContainer, VerifyText, } from "../../../assets/styles/logIn.style";
import { MainBtnStyle, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import { BackArrow } from "../../../components/authComponents/BackButton";
import { isEmail } from "../../../const/validations/validation";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../../store/user/setUser";
import validator from "validator";
import { FormattedMessage, useIntl } from "react-intl";
export var CreatingAccount = function () {
    var _a = useState(""), inputValue = _a[0], setInputValue = _a[1];
    var _b = useState(false), isCheckboxChecked = _b[0], setIsCheckboxChecked = _b[1];
    var navigate = useNavigate();
    var addProp = useUserStore().addProp;
    var intl = useIntl();
    var isFormValid = inputValue.trim() !== "" && isCheckboxChecked;
    var handleInputChange = function (e) {
        setInputValue(e.target.value);
    };
    var validateForm = function () {
        var isEmailValid = isEmail(inputValue);
        var isPhoneValid = validator.isMobilePhone(inputValue);
        return (isEmailValid || isPhoneValid) && isFormValid;
    };
    var handleCheckboxChange = function (e) {
        setIsCheckboxChecked(e.target.checked);
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        if (isEmail(inputValue)) {
            addProp("email", inputValue);
            navigate("/registration/email", { state: { email: inputValue, sendVerificationCode: true } });
        }
        else if (validator.isMobilePhone(inputValue)) {
            addProp("phone", inputValue);
            navigate("/registration/phone", { state: { phone: inputValue } });
        }
    };
    return (_jsx(StyledRow, { "$justifyContent": "center", style: { height: '100%' }, children: _jsxs(StyledCol, { "$lg": 7, "$md": 12, children: [_jsx(StyledCol, { "$justifyContent": "center", "$alignItems": "start", "$lg": 2, "$md": 1, "$sm": 1, "$xs": 1, children: _jsx(BackArrow, {}) }), _jsx(StyledCol, { "$justifyContent": "center", "$lg": 8, "$md": 9, "$sm": 9, "$xs": 9, children: _jsxs(FormContainer, { children: [_jsx(FormContainerText, { children: _jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "creating_account_title" }) }) }), _jsxs(SignUpForm, { onSubmit: handleSubmit, children: [_jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "email_phone_input_label" }) }), _jsx(AuthInput, { placeholder: intl.formatMessage({ id: 'email_phone_input_label' }), value: inputValue, onChange: handleInputChange }), _jsxs(StyledFormContainer, { children: [_jsx(StyledCheckbox, { type: "checkbox", checked: isCheckboxChecked, onChange: handleCheckboxChange }), _jsxs("div", { style: { color: '#FFFFFF' }, children: [_jsx(VerifyText, { children: _jsx(FormattedMessage, { id: "terms_and_policy1" }) }), _jsx(FormattedMessage, { id: "terms_and_policy2" }), _jsx("br", {}), _jsx(FormattedMessage, { id: "terms_and_policy3" }), _jsx(DocumentLink, { href: "#", children: _jsx(FormattedMessage, { id: "terms_and_policy4" }) }), _jsx(FormattedMessage, { id: "terms_and_policy5" }), _jsx(DocumentLink, { href: "#", children: _jsx(FormattedMessage, { id: "terms_and_policy6" }) }), "."] })] }), _jsx(MainBtnStyle, { type: "submit", width: "50%", disabled: !isFormValid, style: {
                                            opacity: validateForm() ? "100%" : "50%",
                                            cursor: validateForm() ? "pointer" : "not-allowed",
                                        }, children: _jsx(FormattedMessage, { id: "next_btn" }) })] })] }) })] }) }));
};
