var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthInput, AuthInputLabel, AuthLink, EmailVerifyLoginForm, FormContainer, FormContainerText, InputWrapper, LogInTitle, VerifyText } from "../../../assets/styles/logIn.style";
import { MainBtnStyle, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import React, { useEffect, useState, useRef } from "react";
import { BackArrow } from "../../../components/authComponents/BackButton";
import CryptoJS from "crypto-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserLoginStore } from "../../../store/user/setUser";
import { Alert } from "../../../components/authComponents/Alert";
import TokenService from '../../../utils/tokenService/tokenService';
import { FormattedMessage, useIntl } from "react-intl";
export var EmailVerifycation = function () {
    var _a;
    var location = useLocation();
    var _b = useState(((_a = location.state) === null || _a === void 0 ? void 0 : _a.email) || ""), email = _b[0], setEmail = _b[1];
    var _c = useState(''), verificationCode = _c[0], setVerificationCode = _c[1];
    var _d = useState(''), enteredCode = _d[0], setEnteredCode = _d[1];
    var _e = useState(false), isResendDisabled = _e[0], setIsResendDisabled = _e[1];
    var navigate = useNavigate();
    var isEmailSent = useRef(false);
    var userObj = useUserLoginStore().userObj;
    var _f = useState(false), showAlert = _f[0], setShowAlert = _f[1];
    var _g = useState(''), errorMessage = _g[0], setErrorMessage = _g[1];
    var intl = useIntl();
    useEffect(function () {
        if (email && !isEmailSent.current) {
            sendEmailVerification(email);
            isEmailSent.current = true;
        }
    }, [email]);
    useEffect(function () {
        var timer;
        if (isResendDisabled) {
            timer = setTimeout(function () { return setIsResendDisabled(false); }, 30000);
            return function () { return clearTimeout(timer); };
        }
    }, [isResendDisabled]);
    var generateCode = function () { return Math.floor(100000 + Math.random() * 900000).toString(); };
    var sendEmailVerification = function (email) { return __awaiter(void 0, void 0, void 0, function () {
        var code, encryptedEmail, encryptedCode, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    code = generateCode();
                    setVerificationCode(code);
                    encryptedEmail = CryptoJS.AES.encrypt(email, process.env.ENCRYPTION_KEY || '').toString();
                    encryptedCode = CryptoJS.AES.encrypt(code, process.env.ENCRYPTION_KEY || '').toString();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/send-email-verify"), {
                            method: 'POST',
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                email: encryptedEmail,
                                code: encryptedCode
                            })
                        })];
                case 2:
                    _a.sent();
                    console.log("Verification code sent successfully.");
                    setIsResendDisabled(true);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Error sending email verification code:", error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var email_1, password, response, errorDetails, error_2, errorDetails, parseError_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    if (!(enteredCode === verificationCode)) return [3 /*break*/, 14];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 13]);
                    email_1 = userObj.email, password = userObj.password;
                    return [4 /*yield*/, sendUserLogin({ email: email_1, password: password })];
                case 2:
                    response = _a.sent();
                    if (!(response && response.ok)) return [3 /*break*/, 3];
                    navigate("/success", { state: { from: '/auth/email-verify' } });
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, response.json()];
                case 4:
                    errorDetails = _a.sent();
                    setErrorMessage(errorDetails.message || "Login failed, please try again.");
                    setShowAlert(true);
                    _a.label = 5;
                case 5: return [3 /*break*/, 13];
                case 6:
                    error_2 = _a.sent();
                    _a.label = 7;
                case 7:
                    _a.trys.push([7, 11, , 12]);
                    if (!(error_2.response && error_2.response.json)) return [3 /*break*/, 9];
                    return [4 /*yield*/, error_2.response.json()];
                case 8:
                    errorDetails = _a.sent();
                    setErrorMessage(errorDetails.message || "An unexpected error occurred.");
                    return [3 /*break*/, 10];
                case 9:
                    setErrorMessage("An unexpected error occurred. Please try again later.");
                    _a.label = 10;
                case 10: return [3 /*break*/, 12];
                case 11:
                    parseError_1 = _a.sent();
                    console.error("Error parsing error response:", parseError_1);
                    setErrorMessage("An unexpected error occurred. Please try again later.");
                    return [3 /*break*/, 12];
                case 12:
                    setShowAlert(true);
                    return [3 /*break*/, 13];
                case 13: return [3 /*break*/, 15];
                case 14:
                    setErrorMessage("The code entered is incorrect. Please try again.");
                    setShowAlert(true);
                    console.log('Invalid code entered');
                    _a.label = 15;
                case 15: return [2 /*return*/];
            }
        });
    }); };
    var handleResendClick = function () {
        if (!isResendDisabled) {
            sendEmailVerification(email);
        }
    };
    var handleCodeChange = function (e) {
        setEnteredCode(e.target.value);
    };
    var sendUserLogin = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var response, responseData, error_3;
        var email = _b.email, password = _b.password;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/user/auth/login"), {
                            method: 'POST',
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                email: email,
                                password: password,
                            }),
                        })];
                case 1:
                    response = _c.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    responseData = _c.sent();
                    console.log(responseData);
                    TokenService.setTokens(responseData.access_token, responseData.refresh_token);
                    return [2 /*return*/, response];
                case 3:
                    error_3 = _c.sent();
                    console.error("Error sending login request:", error_3);
                    throw error_3;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var validateForm = function () {
        return enteredCode.length === 6;
    };
    return (_jsxs(StyledRow, { "$justifyContent": "center", style: { height: '100%' }, children: [_jsxs(StyledCol, { "$lg": 7, "$md": 12, children: [_jsx(StyledCol, { "$justifyContent": "center", "$alignItems": "start", "$lg": 2, "$md": 1, "$sm": 1, "$xs": 1, children: _jsx(BackArrow, {}) }), _jsx(StyledCol, { "$justifyContent": "center", "$lg": 8, "$md": 9, "$sm": 9, "$xs": 9, children: _jsxs(FormContainer, { children: [_jsxs(FormContainerText, { children: [_jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "email_verification_title" }) }), _jsxs(VerifyText, { children: [_jsx(FormattedMessage, { id: "email_verification_description" }), " ", email, "."] })] }), _jsxs(EmailVerifyLoginForm, { onSubmit: handleSubmit, children: [_jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "email_code_title" }) }), _jsx(InputWrapper, { children: _jsx(AuthInput, { value: enteredCode, placeholder: intl.formatMessage({ id: 'input_code_label' }), type: "text", onChange: handleCodeChange }) }), _jsx(MainBtnStyle, { type: "submit", width: "50%", style: {
                                                marginTop: '8px',
                                                opacity: validateForm() ? "100%" : "50%",
                                                cursor: validateForm() ? "pointer" : "not-allowed",
                                            }, children: _jsx(FormattedMessage, { id: "next_btn" }) }), _jsx(AuthLink, { style: isResendDisabled ? {
                                                opacity: '50%',
                                                cursor: 'not-allowed'
                                            } : { opacity: '100%', cursor: 'pointer' }, onClick: handleResendClick, children: isResendDisabled ? _jsx(FormattedMessage, { id: 'wait_code' }) : _jsx(FormattedMessage, { id: "not_get_code" }) })] })] }) })] }), showAlert && (_jsx(Alert, { message: errorMessage, onClose: function () { return setShowAlert(false); }, isSuccess: false }))] }));
};
