var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Navbar, NavLink, InputGroup, Nav, Tabs, Dropdown, ListGroup, Row, Stack, Modal, Image, Container as BootstrapContainer } from "react-bootstrap";
import { ContextAwareToggle } from "../../components/profile/ContextAwareToggle";
export var LoginBtn = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: #FFB801;\n    padding: 16px 20px;\n    border-radius: 16px;\n    border: none;\n    align-items: center;\n    color: #000000;\n    font-weight: 600;\n    font-size: 14px;\n    width: 120px;\n    height: 54px;\n\n    img{\n        padding: 2px;\n    }\n"], ["\n    background-color: #FFB801;\n    padding: 16px 20px;\n    border-radius: 16px;\n    border: none;\n    align-items: center;\n    color: #000000;\n    font-weight: 600;\n    font-size: 14px;\n    width: 120px;\n    height: 54px;\n\n    img{\n        padding: 2px;\n    }\n"])));
export var CartBtn = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: #242424;\n    border-radius: 16px;\n    border: none;\n    padding: 16px 19px;\n    margin-left: 10px;\n"], ["\n    background-color: #242424;\n    border-radius: 16px;\n    border: none;\n    padding: 16px 19px;\n    margin-left: 10px;\n"])));
export var StyledNavbar = styled(Navbar)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0px 64px;\n  .navbar-brand, .nav-link {\n    color: #575757 !important;\n  }\n\n  .navbar-toggler-icon {\n    filter: invert(1);\n  }\n\n  @media (max-width: 540px) {\n    padding: 0px 0px;\n  }\n\n"], ["\n  padding: 0px 64px;\n  .navbar-brand, .nav-link {\n    color: #575757 !important;\n  }\n\n  .navbar-toggler-icon {\n    filter: invert(1);\n  }\n\n  @media (max-width: 540px) {\n    padding: 0px 0px;\n  }\n\n"])));
export var StyledNavLink = styled(NavLink)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\n     && {\n        color: #575757 !important;\n    }\n"], ["\n\n     && {\n        color: #575757 !important;\n    }\n"])));
export var StyledInputGroup = styled(InputGroup)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    background-color: #242424;\n    border-radius: 16px;\n    overflow: hidden;\n    height: 50px;\n    width: 375px;\n\n    @media (max-width: 1200px) {\n      width: 260px;\n    }\n\n    .form-control {\n        background-color: #242424;\n        color: white;\n        border: none;\n\n        &::-webkit-search-cancel-button {\n            -webkit-appearance: none;\n            appearance: none;\n        }\n\n        &:focus {\n            outline: none;\n            box-shadow: none;\n        }\n    }\n\n    .input-group-text {\n        background-color: #242424;\n        border: none;\n        padding: 8px 12px;\n    }\n\n    img {\n        width: 16px;\n        height: auto;\n    }\n"], ["\n    background-color: #242424;\n    border-radius: 16px;\n    overflow: hidden;\n    height: 50px;\n    width: 375px;\n\n    @media (max-width: 1200px) {\n      width: 260px;\n    }\n\n    .form-control {\n        background-color: #242424;\n        color: white;\n        border: none;\n\n        &::-webkit-search-cancel-button {\n            -webkit-appearance: none;\n            appearance: none;\n        }\n\n        &:focus {\n            outline: none;\n            box-shadow: none;\n        }\n    }\n\n    .input-group-text {\n        background-color: #242424;\n        border: none;\n        padding: 8px 12px;\n    }\n\n    img {\n        width: 16px;\n        height: auto;\n    }\n"])));
export var ProfileTop = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
export var StyledNav = styled(Nav)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  .nav-item {\n  }\n\n  .nav-link {\n    color: #575757;\n    padding: 10px 20px;\n    transition: all 0.3s ease;\n    border: none;\n\n    &.active {\n    color: #FFB801;\n      background-color: transparent; \n    }\n\n    &:hover {\n      color: white;\n    }\n\n    &:disabled {\n      background-color: #ccc;\n      color: #999;\n    }\n  }\n"], ["\n  .nav-item {\n  }\n\n  .nav-link {\n    color: #575757;\n    padding: 10px 20px;\n    transition: all 0.3s ease;\n    border: none;\n\n    &.active {\n    color: #FFB801;\n      background-color: transparent; \n    }\n\n    &:hover {\n      color: white;\n    }\n\n    &:disabled {\n      background-color: #ccc;\n      color: #999;\n    }\n  }\n"])));
export var StyledTabs = styled(Tabs)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    border-bottom: #202020 1px solid;\n  .nav-item {\n  }\n\n  .nav-link {\n    color: #575757;\n    padding: 10px 20px;\n    transition: all 0.3s ease;\n    border: none;\n\n    width: 127px;\n\n    &.active {\n    color: #FFB801;\n      background-color: transparent;\n      border-bottom: #FFB801 1px solid;\n    }\n\n    &:hover {\n      color: white;\n    }\n\n    &:disabled {\n      color: #999;\n    }\n  }\n"], ["\n    border-bottom: #202020 1px solid;\n  .nav-item {\n  }\n\n  .nav-link {\n    color: #575757;\n    padding: 10px 20px;\n    transition: all 0.3s ease;\n    border: none;\n\n    width: 127px;\n\n    &.active {\n    color: #FFB801;\n      background-color: transparent;\n      border-bottom: #FFB801 1px solid;\n    }\n\n    &:hover {\n      color: white;\n    }\n\n    &:disabled {\n      color: #999;\n    }\n  }\n"])));
export var FilterBtn = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    background-color: #202020;\n    border-radius: 8px;\n    border: none;\n    color: #FFFFFF;\n    padding: 12px 24px 12px 24px;\n    align-items: center;\n\n    img{\n        padding-right: 8px;\n    }\n"], ["\n    background-color: #202020;\n    border-radius: 8px;\n    border: none;\n    color: #FFFFFF;\n    padding: 12px 24px 12px 24px;\n    align-items: center;\n\n    img{\n        padding-right: 8px;\n    }\n"])));
export var StyledDropdown = styled(Dropdown)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\n    .dropdown-toggle{\n        background-color: transparent;\n        border: 0.5px #FFB801 solid;\n        border-radius: 16px;\n        padding: 12px 10px 12px 10px;\n\n        &::after{\n            margin-left: 30px;\n        }\n    }\n    \n    .dropdown-menu {\n      background-color: #141417;\n      border-radius: 16px !important;\n      overflow: hidden;\n    }\n\n    .dropdown-item {\n      color: #FFFFFF;\n\n      &.active {\n      border-left: 1px #FFB801 solid;\n      background-color: transparent;\n      }\n    }\n\n"], ["\n\n    .dropdown-toggle{\n        background-color: transparent;\n        border: 0.5px #FFB801 solid;\n        border-radius: 16px;\n        padding: 12px 10px 12px 10px;\n\n        &::after{\n            margin-left: 30px;\n        }\n    }\n    \n    .dropdown-menu {\n      background-color: #141417;\n      border-radius: 16px !important;\n      overflow: hidden;\n    }\n\n    .dropdown-item {\n      color: #FFFFFF;\n\n      &.active {\n      border-left: 1px #FFB801 solid;\n      background-color: transparent;\n      }\n    }\n\n"])));
export var BlinkingDot = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  animation: blinker 3s linear infinite;\n  color: #55FF5C;\n  margin: 10px;\n  font-size: 30px;\n\n  @keyframes blinker {\n    50% {\n      opacity: 0;\n    }\n  }\n"], ["\n  animation: blinker 3s linear infinite;\n  color: #55FF5C;\n  margin: 10px;\n  font-size: 30px;\n\n  @keyframes blinker {\n    50% {\n      opacity: 0;\n    }\n  }\n"])));
export var StyledBlikingText = styled.label(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    color: #FFFFFF;\n"], ["\n    color: #FFFFFF;\n"])));
export var FilterSwitch = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  background-color: transparent;\n  border: 0.5px #FFB801 solid;\n  border-radius: 16px;\n  padding: 7px 5px 7px 5px;\n"], ["\n  display: flex;\n  align-items: center;\n  background-color: transparent;\n  border: 0.5px #FFB801 solid;\n  border-radius: 16px;\n  padding: 7px 5px 7px 5px;\n"])));
export var FilterIcon = styled.img(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n"], ["\n"])));
export var FilterDiv = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  background-color: #242424;\n  padding: 4px;\n  display: flex;\n  align-items: center;\n  margin: 2px;\n  border-radius: 6px;\n"], ["\n  background-color: #242424;\n  padding: 4px;\n  display: flex;\n  align-items: center;\n  margin: 2px;\n  border-radius: 6px;\n"])));
export var StyledListGroup = styled(ListGroup)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  border-radius: 20px 20px;\n  margin-top: 50px;\n\n  .list-group-item {\n    background-color: #202020;\n    color: #FFFFFF;\n    border: none;\n    padding: 20px;\n    position: relative;\n    width: 160px;\n\n    @media (max-width: 1600px) {\n      width: 160px;\n    }\n\n    @media (min-width: 992px) {\n      width: 100%;\n    }\n\n    &:not(:last-child)::after {\n      content: \"\";\n      position: absolute;\n      top: 30%;\n      right: 0;\n      width: 1px;\n      height: 40%;\n      background-color: #575757;\n    }\n\n    h4 {\n      font-weight: 700;\n      font-size: 18px;\n    }\n\n    span {\n      color: #575757;\n      font-weight: 500;\n      font-size: 14px;\n    }\n\n    &:last-child {\n      border-top-right-radius: 20px !important;\n    }\n    \n    &:first-child {\n      border-bottom-left-radius: 20px !important;\n    }\n  }\n"], ["\n  border-radius: 20px 20px;\n  margin-top: 50px;\n\n  .list-group-item {\n    background-color: #202020;\n    color: #FFFFFF;\n    border: none;\n    padding: 20px;\n    position: relative;\n    width: 160px;\n\n    @media (max-width: 1600px) {\n      width: 160px;\n    }\n\n    @media (min-width: 992px) {\n      width: 100%;\n    }\n\n    &:not(:last-child)::after {\n      content: \"\";\n      position: absolute;\n      top: 30%;\n      right: 0;\n      width: 1px;\n      height: 40%;\n      background-color: #575757;\n    }\n\n    h4 {\n      font-weight: 700;\n      font-size: 18px;\n    }\n\n    span {\n      color: #575757;\n      font-weight: 500;\n      font-size: 14px;\n    }\n\n    &:last-child {\n      border-top-right-radius: 20px !important;\n    }\n    \n    &:first-child {\n      border-bottom-left-radius: 20px !important;\n    }\n  }\n"])));
export var SotialIconsContainer = styled.a(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  background-color: #575757;\n  padding: 6px 8px 8px;\n  border-radius: 8px;\n  margin-right: 8px;\n"], ["\n  background-color: #575757;\n  padding: 6px 8px 8px;\n  border-radius: 8px;\n  margin-right: 8px;\n"])));
export var ProfileLink = styled.a(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  color: #969595;\n  margin-top: 10px;\n\n  &:hover{\n    color: white;\n  }\n"], ["\n  color: #969595;\n  margin-top: 10px;\n\n  &:hover{\n    color: white;\n  }\n"])));
export var StyledProfileData = styled.span(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  color: #FFFFFF;\n  margin-right: 30px;\n"], ["\n  color: #FFFFFF;\n  margin-right: 30px;\n"])));
export var ProfileDataContainer = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  color: #575757;\n"], ["\n  color: #575757;\n"])));
export var EarningsPersent = styled.span(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  color: #62FF3B;\n"], ["\n  color: #62FF3B;\n"])));
export var ProfileNameContainer = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  margin-bottom: 30px;\n"], ["\n  margin-bottom: 30px;\n"])));
//ts-ignore
export var ProfileStyledRow = styled(Row)(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n    margin-top: 70px;\n    align-items: center;\n\n    h4 {\n      color: #FFFFFF;\n      font-weight: 700;\n      font-size: 28px;\n      margin-bottom: 0px;\n    }\n\n    label {\n      color: #575757;\n      font-weight: 500;\n      font-size: 14px;\n    }\n\n"], ["\n    margin-top: 70px;\n    align-items: center;\n\n    h4 {\n      color: #FFFFFF;\n      font-weight: 700;\n      font-size: 28px;\n      margin-bottom: 0px;\n    }\n\n    label {\n      color: #575757;\n      font-weight: 500;\n      font-size: 14px;\n    }\n\n"])));
export var PrifileAccordionText = styled.p(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  color: #969595 !important;\n  margin-bottom: 0px;\n"], ["\n  color: #969595 !important;\n  margin-bottom: 0px;\n"])));
export var StyledContextAwareToggle = styled(ContextAwareToggle)(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  color: red;\n  border: none;\n  padding: 10px;\n  font-size: 16px;\n  &:hover {\n    background-color: rgba(255, 0, 0, 0.8);\n  }\n"], ["\n  color: red;\n  border: none;\n  padding: 10px;\n  font-size: 16px;\n  &:hover {\n    background-color: rgba(255, 0, 0, 0.8);\n  }\n"])));
export var StyledMoreBtn = styled.a(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  color: #FFFFFF !important;\n  font-weight: 700;\n  font-size: 14px;\n  margin: 5px;\n  text-decoration: none;\n"], ["\n  color: #FFFFFF !important;\n  font-weight: 700;\n  font-size: 14px;\n  margin: 5px;\n  text-decoration: none;\n"])));
export var AvatarImg = styled.img(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  position: absolute;\n  width: 100px;\n  height: auto;\n  z-index: 2;\n  top: -120%;\n  left: 30px;\n\n  @media (max-width: 540px) {\n    top: -120%;\n    left: 50%;\n    transform: translateX(-50%);\n  }\n"], ["\n  position: absolute;\n  width: 100px;\n  height: auto;\n  z-index: 2;\n  top: -120%;\n  left: 30px;\n\n  @media (max-width: 540px) {\n    top: -120%;\n    left: 50%;\n    transform: translateX(-50%);\n  }\n"])));
export var ProfileImageBackground = styled(Image)(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  min-height: 140px;\n  width: auto;\n"], ["\n  min-height: 140px;\n  width: auto;\n"])));
export var ResponsiveStack = styled(Stack)(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  flex-direction: row;\n\n  @media (min-width: 1200px) {\n    flex-direction: column;\n  }\n"], ["\n  flex-direction: row;\n\n  @media (min-width: 1200px) {\n    flex-direction: column;\n  }\n"])));
export var StyledButton = styled.button(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  background-color: #1a1a1a;\n  color: #fff;\n  border: 1px solid #444;\n  padding: 10px 20px;\n  margin-top: 15px;\n  width: 100%;\n  &:hover {\n    background-color: #333;\n    color: #fff;\n    border-color: #555;\n  }\n"], ["\n  background-color: #1a1a1a;\n  color: #fff;\n  border: 1px solid #444;\n  padding: 10px 20px;\n  margin-top: 15px;\n  width: 100%;\n  &:hover {\n    background-color: #333;\n    color: #fff;\n    border-color: #555;\n  }\n"])));
export var StyledModal = styled(Modal)(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  .modal-content {\n    background-color: #222;\n    color: #fff;\n    box-shadow: none;\n  }\n  .modal-header,\n  .modal-footer {\n    border-color: #444;\n  }\n  .modal-title {\n    color: #fff;\n  }\n  .btn-close {\n    filter: invert(1);\n    outline: none;\n  }\n  .modal-content:focus,\n  .modal-header:focus,\n  .modal-body:focus,\n  .modal-footer:focus,\n  .modal-dialog:focus {\n    outline: none;\n    box-shadow: none;\n  }\n"], ["\n  .modal-content {\n    background-color: #222;\n    color: #fff;\n    box-shadow: none;\n  }\n  .modal-header,\n  .modal-footer {\n    border-color: #444;\n  }\n  .modal-title {\n    color: #fff;\n  }\n  .btn-close {\n    filter: invert(1);\n    outline: none;\n  }\n  .modal-content:focus,\n  .modal-header:focus,\n  .modal-body:focus,\n  .modal-footer:focus,\n  .modal-dialog:focus {\n    outline: none;\n    box-shadow: none;\n  }\n"])));
export var ResponsiveContainer = styled(BootstrapContainer)(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  padding-left: 0px;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n  }\n\n  @media (min-width: 769px) {\n    flex-direction: row;\n    justify-content: end;\n    gap: 0;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  padding-left: 0px;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n  }\n\n  @media (min-width: 769px) {\n    flex-direction: row;\n    justify-content: end;\n    gap: 0;\n  }\n"])));
export var PrifileFilterContainer = styled.div(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  display: flex;\n  gap: 10px;\n\n  @media (max-width: 991px) {\n    margin-top: 10px;\n  }\n"], ["\n  display: flex;\n  gap: 10px;\n\n  @media (max-width: 991px) {\n    margin-top: 10px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33;
