import { LOCALES } from "../../locales/locales";
export var LocalizeLanguage = [
    {
        short: 'Eng',
        full: 'English',
        code: LOCALES.ENGLISH,
    },
    {
        short: 'Esp',
        full: 'Español',
        code: LOCALES.SPANISH
    },
    {
        short: 'Cze',
        full: 'Čeština',
        code: LOCALES.CZECH
    },
    {
        short: 'Ara',
        full: 'العربية',
        code: LOCALES.ARABIC
    },
    {
        short: 'Heb',
        full: 'עברית',
        code: LOCALES.HEBREW
    },
    {
        short: 'Ita',
        full: 'Italiano',
        code: LOCALES.ITALIAN
    },
    {
        short: 'Pol',
        full: 'Polski',
        code: LOCALES.POLISH
    },
    {
        short: 'Rus',
        full: 'Русский',
        code: LOCALES.RUSSIAN
    },
    {
        short: 'Ukr',
        full: 'Українська',
        code: LOCALES.UKRAINIAN
    },
    {
        short: 'Deu',
        full: 'Deutsch',
        code: LOCALES.GERMAN
    },
    {
        short: 'Fra',
        full: 'Français',
        code: LOCALES.FRENCH
    }
];
