import { jsx as _jsx } from "react/jsx-runtime";
import { MainBtnStyle } from "../../assets/styles/mainPageStyles";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
export var NavigateBtn = function (_a) {
    var link = _a.link, _b = _a.text, text = _b === void 0 ? "Next" : _b, validate = _a.validate, onClick = _a.onClick, _c = _a.width, width = _c === void 0 ? "100%" : _c;
    var navigate = useNavigate();
    var handleClick = function (e) {
        if (onClick) {
            onClick(e);
        }
        if (validate) {
            navigate(link);
        }
    };
    return (_jsx(MainBtnStyle, { onClick: handleClick, style: {
            opacity: validate ? "100%" : "50%",
            cursor: validate ? "pointer" : "not-allowed",
        }, type: "button", width: width, children: _jsx(FormattedMessage, { id: "next_btn", defaultMessage: text }) }));
};
