import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Header from "../components/header/Header";
import { LogInContainer, SpaceBackground } from "../assets/styles/logIn.style";
import { Outlet } from 'react-router-dom';
import AuthGlobeLayout from "./AuthGlobeLayout";
var AuthLayout = function (_a) {
    var currentLocale = _a.currentLocale, onLocaleChange = _a.onLocaleChange;
    return (_jsxs(SpaceBackground, { children: [_jsx(Header, { currentLocale: currentLocale, onLocaleChange: onLocaleChange }), _jsx(AuthGlobeLayout, {}), _jsx(LogInContainer, { children: _jsx(Outlet, {}) })] }));
};
export default AuthLayout;
