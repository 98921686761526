var TokenService = /** @class */ (function () {
    function TokenService() {
        this.ACCESS_TOKEN_KEY = 'accessToken';
        this.REFRESH_TOKEN_KEY = 'refreshToken';
    }
    TokenService.prototype.setTokens = function (accessToken, refreshToken) {
        localStorage.setItem(this.ACCESS_TOKEN_KEY, accessToken);
        localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
    };
    // Access Token
    TokenService.prototype.getAccessToken = function () {
        return localStorage.getItem(this.ACCESS_TOKEN_KEY);
    };
    // Refresh Token
    TokenService.prototype.getRefreshToken = function () {
        return localStorage.getItem(this.REFRESH_TOKEN_KEY);
    };
    TokenService.prototype.clearTokens = function () {
        localStorage.removeItem(this.ACCESS_TOKEN_KEY);
        localStorage.removeItem(this.REFRESH_TOKEN_KEY);
    };
    return TokenService;
}());
export default new TokenService();
