import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { AuthInput, AuthInputLabel, AuthLink, FormContainer, FormContainerText, LogInTitle, VerifyText } from "../../../assets/styles/logIn.style";
import { MainBtnStyle, PositionLayout, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import { BackArrow } from "../../../components/authComponents/BackButton";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
export var PhoneVerifycationCode = function () {
    var _a = useState(true), isSended = _a[0], setIsSended = _a[1];
    var _b = useState(false), isTimerActive = _b[0], setIsTimerActive = _b[1];
    var navigate = useNavigate();
    var intl = useIntl();
    useEffect(function () {
        var timer;
        if (isTimerActive) {
            timer = setTimeout(function () {
                setIsSended(true);
                setIsTimerActive(false);
            }, 30000);
            return function () { return clearTimeout(timer); };
        }
    }, [isTimerActive]);
    var handleResendClick = function () {
        if (!isTimerActive) {
            setIsSended(false);
            setIsTimerActive(true);
        }
    };
    return (_jsx(StyledRow, { "$justifyContent": "center", style: { height: '100%' }, children: _jsxs(StyledCol, { "$lg": 7, "$md": 12, children: [_jsx(StyledCol, { "$justifyContent": "center", "$alignItems": "start", "$lg": 2, "$md": 1, "$sm": 1, "$xs": 1, children: _jsx(BackArrow, {}) }), _jsx(StyledCol, { "$justifyContent": "center", "$lg": 8, "$md": 9, "$sm": 9, "$xs": 9, children: _jsxs(FormContainer, { children: [_jsxs(FormContainerText, { children: [_jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "phone_verification_title" }) }), _jsxs(VerifyText, { children: [_jsx(FormattedMessage, { id: 'phone_verification_text1' }), _jsx("br", {}), _jsx(FormattedMessage, { id: 'phone_verification_text2' })] })] }), _jsxs(PositionLayout, { display: "flex", flexDirection: "column", width: "85%", children: [_jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "phone_verification_code_label" }) }), _jsx(AuthInput, { placeholder: intl.formatMessage({ id: 'input_code_label' }) }), _jsx(MainBtnStyle, { type: "submit", width: "50%", onClick: function () { return navigate('/registration/creating-password'); }, children: _jsx(FormattedMessage, { id: "next_btn" }) }), _jsx(AuthLink, { style: isSended ? { opacity: '100%', color: '' } : { opacity: '50%', color: '#FFFFFF' }, onClick: handleResendClick, children: isSended ? _jsx(FormattedMessage, { id: 'wait_code' }) : _jsx(FormattedMessage, { id: "not_get_code" }) })] })] }) })] }) }));
};
