export var options = [
    { value: 'GB', label: 'United Kingdom' },
    { value: 'ES', label: 'Spain' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'PL', label: 'Poland' },
    { value: 'RU', label: 'Russia' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'DE', label: 'Germany' },
    { value: 'FR', label: 'France' }
];
